import React from "react"
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

const Image = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
}
`}
    render={(data) => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt="Chicken Yard" />
    )}
  />
)

const Sidebar = () => (
  <aside>
    <section>
      <Widget>
        <CardBody>
          <BioAvatar>
            <Image />
          </BioAvatar>
          <CardTitle className="text-center text-white mb-0">
            <h5>
              <span style={{ color: `#e39f1e` }}>Got chickens?</span> We do too.
            </h5>
          </CardTitle>
          <BioText>
            <small>
              <b>
                <u>Everything</u>
              </b>{" "}
              you need to know about feeding, raising and housing chickens.{" "}
              <span style={{ color: `#e39f1e` }}>
                <b>Right here</b>
              </span>
              .
            </small>
          </BioText>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle className="text-center text-white mb-3">
            <h5>
              Want a copy of our famous guide <em>Keeping Chickens</em> (completely free)?
              <br />
              <span style={{ color: `#e39f1e` }}>SIGN UP:</span>
            </h5>
          </CardTitle>
          <Form
            className="text-center"
            action="https://www.getdrip.com/forms/122709652/submissions"
            method="post"
            data-drip-embedded-form="122709652"
          >
            <FormGroup>
              <Input
                type="email"
                name="fields[email]"
                id="drip-email"
                aria-label="Email"
                placeholder="Your email address..."
              />
            </FormGroup>
            <button
              data-drip-attribute="sign-up-button"
              className="btn text-white text-uppercase joinsubmit"
            >
              Give It To Me
            </button>
          </Form>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: `#e39f1e` }}>Resources</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href="/resources/">
              Chicken Essentials
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: `#e39f1e` }}>Popular Articles</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem
              bold
              tag="a"
              href="/what-is-a-group-of-chickens-called/"
            >
              What Is A Group Of Chickens Called?
            </MenuItem>
            <MenuItem bold tag="a" href="/do-chickens-have-talons-or-claws/">
            Do Chickens Have Talons Or Claws?
            </MenuItem>
            <MenuItem bold tag="a" href="/are-chickens-mammals-or-reptiles/">
            Are Chickens Mammals Or Reptiles?
            </MenuItem>
            <MenuItem bold tag="a" href="/what-is-the-difference-between-fowl-and-poultry/">
            What's The Difference Between Fowl And Poultry?
            </MenuItem>
            <MenuItem
              bold
              tag="a"
              href="/best-chicken-coop-kits-for-under-300/"
            >
              Best Chicken Coups Under $300
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: `#e39f1e` }}>Categories</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href="/topic/raising-chickens/">
              Raising Chickens
            </MenuItem>

            <MenuItem tag="a" href="/topic/coop-designs/">
            Chicken Coop Designs
            </MenuItem>

            <MenuItem tag="a" href="/topic/coop-kits/">
            Chicken Coop Kits
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
    </section>
    <section />
  </aside>
)

export default Sidebar

const Widget = styled(Card)`
  background-color: #231f1a !important;
  border-radius: 2px;
  border: none !important;
  margin-bottom: 0.5rem;
  //min-height: 200px;
  text-align: center;
`
const BioAvatar = styled.div`
  margin: auto;
  margin-bottom: 15px;
  max-width: 200px;
  align-self: center;
  img {
    background-color: #e39f1e;
    border-radius: 50%;
    border: 8px solid #e39f1e;
  }
`
const BioText = styled(CardText)`
  color: #fff;
  line-height: 1.2;
`
const SidebarLink = styled(Link)`
  color: #fff !important;
  &:hover {
    color: #e39f1e;
  }
`
const MenuItem = styled(ListGroupItem)`
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  &:hover {
    background: #e39f1e !important;
  }
`
